<template>
  <a-card class='mb15'>
    <div class="s_flex_bian_c">
      <div class="s_flex_ali">
        <img class="width-20 height-20 m-r-10" src="../assets/images/data.png" alt="">
        <p class="s_font_b fz-18 s_c000">概况</p>
      </div>
      <a-form ref='form' :model='search' :initialValues="search" layout='inline' :onFinish='getStatisticsData'>
        <a-form-item>
          <a-radio-group button-style="solid" @change="onChangeRadio" v-model:value="search.recent_day_nums">
            <a-radio-button :value="7">近7天</a-radio-button>
            <a-radio-button :value="30">近30天</a-radio-button>
            <a-radio-button :value="2">月</a-radio-button>
            <a-radio-button :value="3">年</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="" v-if='search.recent_day_nums==2'>
          <a-month-picker v-model:value="month" valueFormat='YYYY-MM' format='YYYY-MM' placeholder="请选择月份" @change="onChangeMonth" />
        </a-form-item>
        <a-form-item label="" v-if='search.recent_day_nums==3'>
          <a-select
            v-model:value="search.year"
            style="width: 150px"
            @change="onChangeYear"
            placeholder="请选择年份"
          >
            <a-select-option v-for='(item,index) in yearArr' :key='index' :value="item">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChangeRange" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-divider />
    <!-- <div class="s_flex_bian_c">
      <div class="s_flex_bian_c width46 s_bor_cb4c s_bor5 s_over">
        <div class="width35 p-30 s_shou" :class='search.stat_type == "brandStat" ? "s_bf5f":""' @click='tabBnt("brandStat","新增品牌商")'>
          <p class="fz-16 s_c000">品牌商总数</p>
          <p class="s_font_b s_c000 fz-30 m-t-12 m-b-12">{{brand_total.total_count || 0}}</p>
          <div class="s_c999 height-22">
            <div class="s_flex_ali" v-if='brand_total.time_range_count > 0'>
              <p>期间增长</p>
              <span class="balanceUp m-l-5 m-r-5">{{brand_total.time_range_count || 0}}</span>
              <img class="width-10 height-16" src="../assets/images/up.png" alt="">
            </div>
          </div>
        </div>
        <div class="width35 p-30 s_shou s_bor_cb4c_bl1" :class='search.stat_type == "agentStat" ? "s_bf5f":""' @click='tabBnt("agentStat","新增代理商")'>
          <p class="fz-16 s_c000">代理商总数</p>
          <p class="s_font_b s_c000 fz-30 m-t-12 m-b-12">{{agent_count.total_count || 0}}</p>
          <div class="s_c999 height-22">
            <div class="s_flex_ali" v-if='agent_count.time_range_count > 0'>
              <p>期间增长</p>
              <span class="balanceUp m-l-5 m-r-5">{{agent_count.time_range_count || 0}}</span>
              <img class="width-10 height-16" src="../assets/images/up.png" alt="">
            </div>
          </div>
        </div>
        <div class="width35 p-30 s_shou s_bor_cb4c_bl1" :class='search.stat_type == "commissionStat" ? "s_bf5f":""' @click='tabBnt("commissionStat","平台佣金")'>
          <p class="fz-16 s_c000">平台佣金</p>
          <p class="s_font_b s_c000 fz-30 m-t-12 m-b-12">{{commission_total.total_count || 0}}</p>
          <div class=" s_c999 height-22">
            <div class="s_flex_ali" v-if='commission_total.time_range_count > 0'>
              <p>期间增长</p>
              <span class="balanceUp m-l-5 m-r-5">{{commission_total.time_range_count || 0}}</span>
              <img class="width-10 height-16" src="../assets/images/up.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="width14">
        <div class="height-80 s_bor_cb4c s_bor5 m-b-20 s_flex_ali s_over">
          <div class="fz-16 s_c000 height-80 width-40 s_flex_center s_c376" style="background-color: #B5C3D2;">
            <p class="width-30 s_text_c">续费期</p>
          </div>
          <p class="s_font_b balanceUp fz-30 width83 s_text_c">{{brand_renewal_total.total_count || 0}}</p>
        </div>
        <div class="height-80 s_bor_cb4c s_bor5 s_flex_ali s_over">
          <div class="fz-16 s_c000 height-80 width-40 s_flex_center s_c376" style="background-color: #B5C3D2;">
            <p class="width-30 s_text_c">已过期</p>
          </div>
          <p class="s_font_b s_c000 fz-30 width83 s_text_c">{{brand_expire_total.total_count || 0}}</p>
        </div>
      </div>
    </div> -->
    <!-- 优化 -->
    <div style="width:98%;display:flex;box-sizing: border-box;margin:0 auto;">
      <a-card hoverable style="flex:1" class="active">
        <a-spin :spinning="listLoading" style="width:100%;min-height:100px;padding-top:50px">
         <echartPie ref="brandPie" :echartsData='brandData' v-if="!listLoading"></echartPie>
         <div class="s_flex_ali" v-if='brand_total.time_range_count > 0'>
              <p>期间增长</p>
              <span class="balanceUp m-l-5 m-r-5">{{brand_total.time_range_count || 0}}</span>
              <img class="width-10 height-16" src="../assets/images/up.png" alt="">
          </div>
          </a-spin>
      </a-card>
      <a-card hoverable style="flex:1" class="active">
        <a-spin :spinning="listLoading" style="width:100%;min-height:100px;padding-top:50px">
        <echartPie ref="agentPie" :echartsData="agentData" v-if="!listLoading"></echartPie>
          <div class="s_flex_ali" v-if='agent_count.time_range_count > 0'>
              <p>期间增长</p>
              <span class="balanceUp m-l-5 m-r-5">{{agent_count.time_range_count || 0}}</span>
              <img class="width-10 height-16" src="../assets/images/up.png" alt="">
          </div>
           </a-spin>
      </a-card>
      <a-card hoverable style="flex:1" class="active" >
        <a-spin :spinning="listLoading" style="width:100%;min-height:100px;padding-top:50px">
        <!-- <echartPie ref="commissionPie" :echartsData="commissionData" v-if="!listLoading"></echartPie> -->
        <echartBar ref="commissionPie" :echartsData="commissionData" v-if="!listLoading"></echartBar>
          <div class="s_flex_ali" v-if='commission_total.time_range_count > 0'>
              <p>期间增长</p>
              <span class="balanceUp m-l-5 m-r-5">{{commission_total.time_range_count || 0}}</span>
              <img class="width-10 height-16" src="../assets/images/up.png" alt="">
          </div>
           </a-spin>
      </a-card>
    </div>

    <!--  -->
    <div class="m-t-30">
      <a-spin :spinning="listLoading" style="width:100%;padding-top:50px">
        <!-- <div style="display:flex"> -->
          <div class="p-r-20">
              <a-radio-group button-style="solid" @change="tabBnt" v-model:value="search.stat_type" style="display:flex; align-items: center;width:100%;justify-content: flex-end;">
                <a-radio-button value="brandStat">新增品牌商</a-radio-button>
                <a-radio-button value="agentStat">新增代理商</a-radio-button>
                <a-radio-button  value="commissionStat" style="width:101px;text-align: center;">平台佣金</a-radio-button>
              </a-radio-group>
          </div>
          <div style="width:100%;min-height:580px">
             <echartLine :echartsData='echartsData' v-if='!listLoading' style="width:100%"></echartLine>
          </div>
          <!-- <div style="width:90%" v-else></div>
          <div style="width:140px;padding-top:50px;">
          <a-radio-group button-style="solid" @change="tabBnt" v-model:value="search.stat_type" style="width:140px">
            <a-radio-button value="brandStat">新增品牌商</a-radio-button>
            <a-radio-button value="agentStat">新增代理商</a-radio-button>
            <a-radio-button  value="commissionStat" style="width:101px;text-align: center;">平台佣金</a-radio-button>
          </a-radio-group>
          </div> -->
        <!-- </div> -->
      </a-spin>
    </div>
  </a-card>

  <a-card>
    <p class="s_font_b fz-18 s_c000 m-b-25">排行</p>
    <a-row :gutter="30">
      <a-col :span="8">
        <p class="m-b-15 s_c000 fz-16">应用Top10</p>
        <a-table :pagination='false' :loading='tableLoading' :columns='columns' rowKey='name' :data-source='sys_apps_sales_rank' bordered>
          <template #nameTitle>
            应用名称
          </template>
        </a-table>
      </a-col>
      <a-col :span="8">
        <p class="m-b-15 s_c000 fz-16">电子二维码Top10</p>
        <a-table :pagination='false' :loading='tableLoading' :columns='columns' rowKey='name' :data-source='data' bordered>
          <template #nameTitle>
            品牌商名称
          </template>
        </a-table>
      </a-col>
      <a-col :span="8">
        <p class="m-b-15 s_c000 fz-16">短信Top10</p>
        <a-table :pagination='false' :loading='tableLoading' :columns='columns' rowKey='name' :data-source='sms_sales_brand_rank' bordered>
          <template #nameTitle>
            品牌商名称
          </template>
        </a-table>
      </a-col>
    </a-row>
  </a-card>
</template>
<script>

  import { reactive, ref, toRefs, onMounted, inject } from "vue";
  import { getStatisticsIndex,getRankIndex,getRankSysApps,getRankSms } from "@/api/index";
  import { $iscode } from "@/utils/app";
  import { message } from "ant-design-vue";
  const defSearch = {
    start_time: "",
    end_time: "",
    recent_day_nums: 7,
    year:null,
    month:'',
    stat_type:'brandStat',
  };
  export default {
    name: "Home",
    setup() {
      let search = ref({ ...defSearch });
      const brandPie = ref(null);
      const agentPie = ref(null)
      const commissionPie = ref(null)
      const state = reactive({
        tableLoading:true,
        listLoading: true,
        echartsData: {},
        echarts_name:'新增品牌商',
        brand_total: {},
        agent_count:{},
        commission_total:{},
        brand_renewal_total:{},
        brand_expire_total:{},
        sms_sales_brand_rank:[],
        sys_apps_sales_rank:[],
        data:[],
        time: [],
        month:'',
        yearArr:[],
        brandData:{},
        agentData:{},
        commissionData:{}
      });
      const columns = [
        {
          dataIndex: "name",
          key: "name",
          slots: { title: 'nameTitle' },
        },
        {
          title: "销量(个)",
          dataIndex: "sale_count",
          key: "sale_count",
        },
      ]
      // 单选
      const onChangeRadio = (e) => {
        search.value.start_time = "";
        search.value.end_time = "";
        state.time = [];
      };
      // 选择时间段
      const onChangeRange = (e) => {
        search.value.start_time = e[0];
        search.value.end_time = e[1];
        search.value.recent_day_nums = ''
        search.value.year = ''
        search.value.month = ''
      };
      //选择月份
      const onChangeMonth = (e) =>{
        search.value.start_time = "";
        search.value.end_time = "";
        state.time = [];
        search.value.year = ''
        search.value.month = e.split('-')[1]
      };
      //选择年
      const onChangeYear= (e) =>{
        search.value.start_time = "";
        search.value.end_time = "";
        state.time = [];
        search.value.month = ''
        search.value.year = e
      };
      // 
      const tabBnt = (e) =>{
        if(e.target.value == 'brandStat' ){
           state.echarts_name = '新增品牌商';
        }else if(e.target.value == 'agentStat'){
          state.echarts_name = '新增代理商';
        }else{
          state.echarts_name = '平台佣金';
        }
        search.value.stat_type = e.target.value
        getStatisticsData()
      }
      const getStatisticsData = async() =>{
        state.listLoading = true;
        let res = await getStatisticsIndex(search.value).then(res => res.data);
        if ($iscode(res)) {
          state.brand_total = res.data.brand_total;
          state.agent_count = res.data.agent_count;
          state.commission_total = res.data.commission_total;
          state.brand_renewal_total = res.data.brand_renewal_total;
          state.brand_expire_total = res.data.brand_expire_total;
          state.echartsData = {...res.data.statistics,name:state.echarts_name}
          let barX = [];
          let barY = [];
         if(state.commission_total.brand_count.length!=0){
           state.commission_total.brand_count.forEach(item => {
                barX.push(item.name)
                let num = Number(item.total) || 0;
                barY.push(num)
           });
         }
          // 图表
          state.brandData = {
                name:'品牌商',
                total:state.brand_total.total_count || 0,
                data: [{ value: (state.brand_expire_total.total_count || 0), name: "已过期",url:'/brand/brand_list?time_type=3' },
                      { value: (state.brand_total.total_count-(state.brand_expire_total.total_count+state.brand_renewal_total.total_count)), name: "正常期",url:'/brand/brand_list?time_type=1' },
                      { value: (state.brand_renewal_total.total_count || 0), name: "续费期",url:'/brand/brand_list?time_type=2' }]
              }
              state.agentData = {
                name:'代理商',
                total:state.agent_count.total_count || 0,
                data: [{ value: (state.agent_count.total_count - state.agent_count.normal_count), name: "已过期",url:'/agent/agent_list?type=2' }, //接口没有返回
                      // { value: 0, name: "续费期",url:'/agent/agent_list?type=2'},  //接口没有返回
                      { value: state.agent_count.normal_count, name: "正常期",url:'/agent/agent_list?type=1'}]
              }
            state.commissionData = {
                 name:`平台佣金:￥${state.commission_total.total_count}`,
                 total:state.commission_total.total_count || 0,
                 data:barY,
                 x:barX
               }
          if(brandPie.value&&agentPie.value&&commissionPie.value){
              brandPie.value.init(state.brandData)
              agentPie.value.init(state.agentData)
              commissionPie.value.init(state.commissionData)
          }
          state.listLoading = false;
        } else {
          state.listLoading = false;
          message.error(res.msg);
        }
        
      }
      const getRankData = async() =>{
        let res = await getRankIndex().then(res => res.data);
        if ($iscode(res)) {
          state.sms_sales_brand_rank = res.data.sms_sales_brand_rank.data;
          state.sys_apps_sales_rank = res.data.sys_apps_sales_rank.data
        } else {
          message.error(res.msg);
        }
        state.tableLoading = false;
      }
      onMounted(() => {
        getStatisticsData()
        getRankData()
        
        var nfOptionsArray = new Array();
        var years= new Date().getFullYear();
        for(var i=years-5;i<=years+5;i++){
          nfOptionsArray.push(i);
        }
        state.yearArr = nfOptionsArray
      });
      return {
        ...toRefs(state),
        search,
        columns,
        onChangeRadio,
        onChangeRange,
        onChangeMonth,
        getStatisticsData,
        getRankData,
        onChangeYear,
        tabBnt,
        brandPie,
        agentPie,
        commissionPie
      };
    },
  };
</script>
<style>
  .balanceUp {
    color: #DF412C;
  }

  .balanceDown {
    color: #4BB339;
  }
  .active{
        border-color: rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
  }
 .active:nth-child(2){
   border-left: 0;
   border-right: 0;
   position: relative;
   z-index: 1;
 }
</style>